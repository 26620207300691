import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1020.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M2742 8379 c-13 -5 -47 -24 -75 -42 -59 -38 -328 -204 -415 -256 -31
-19 -145 -89 -253 -156 -107 -67 -231 -144 -275 -170 -43 -26 -95 -57 -114
-70 -38 -24 -96 -60 -455 -280 -132 -81 -243 -152 -247 -158 -16 -26 -27 -7
282 -507 64 -104 89 -145 115 -189 11 -18 35 -56 53 -83 17 -28 32 -54 32 -59
0 -5 5 -9 10 -9 6 0 10 -5 10 -12 0 -24 125 -207 142 -207 12 -1 29 23 53 72
19 39 47 92 61 117 14 25 56 106 95 180 38 74 90 176 117 225 26 50 96 183
156 297 85 161 114 209 135 217 27 11 289 22 956 41 209 6 396 13 415 16 35 6
420 19 868 30 179 5 242 10 242 19 0 6 -46 35 -102 63 -99 50 -213 110 -363
192 -38 20 -119 63 -180 95 -60 32 -137 72 -170 90 -33 18 -100 54 -150 80
-85 45 -155 82 -285 152 -368 197 -590 312 -610 316 -14 3 -35 1 -48 -4z"
          />
          <path
            d="M8440 6807 l-75 -43 -2 -99 -3 -100 75 -42 c41 -24 80 -43 86 -43 7
0 45 19 86 43 l73 42 -2 100 -3 99 -75 43 c-41 24 -77 43 -80 43 -3 0 -39 -19
-80 -43z"
          />
          <path
            d="M2631 6741 c-8 -5 -18 -37 -23 -73 -27 -205 -88 -649 -104 -758 -9
-58 -20 -134 -24 -170 -5 -36 -16 -120 -26 -188 -10 -72 -13 -127 -8 -132 13
-12 1031 -17 1043 -5 13 13 43 209 35 229 -5 14 -48 16 -349 16 -293 0 -344 2
-355 15 -10 12 -10 37 0 125 7 60 18 117 24 125 9 13 41 16 177 18 l165 2 110
86 c60 47 131 100 157 118 26 18 46 38 44 44 -3 9 -87 13 -306 15 -341 3 -321
-3 -303 85 6 29 13 77 17 108 11 99 -16 92 374 93 270 1 343 4 350 14 11 19
34 209 27 227 -5 13 -69 15 -508 15 -281 0 -509 -4 -517 -9z"
          />
          <path
            d="M4061 6737 c-6 -8 -20 -81 -31 -163 -11 -82 -31 -230 -45 -329 -14
-99 -35 -250 -46 -335 -10 -85 -24 -177 -29 -205 -17 -94 -30 -222 -24 -238 3
-8 11 -17 17 -19 7 -2 246 -1 532 2 l520 5 65 31 c36 17 84 49 106 72 79 81
99 145 139 447 16 123 27 201 45 316 15 98 13 220 -5 263 -23 55 -79 111 -138
137 l-52 24 -521 3 c-440 2 -523 1 -533 -11z m856 -263 c15 -11 36 -36 46 -55
24 -44 18 -162 -18 -403 -19 -132 -30 -179 -50 -212 -37 -62 -82 -93 -154
-105 -66 -10 -472 -6 -490 5 -14 9 -14 53 -1 125 12 65 39 256 50 356 18 157
41 289 52 302 10 12 56 14 274 11 247 -3 264 -4 291 -24z"
          />
          <path
            d="M6219 6743 c-13 -3 -81 -109 -156 -243 -11 -19 -51 -87 -89 -150 -38
-63 -72 -123 -76 -132 -4 -9 -18 -34 -31 -55 -13 -21 -36 -59 -50 -85 -79
-142 -126 -224 -187 -328 -140 -240 -167 -285 -173 -302 -12 -34 16 -40 175
-36 l153 3 315 157 c174 87 330 161 348 164 42 9 65 -10 57 -47 -9 -41 42
-227 73 -261 13 -16 34 -18 157 -18 161 0 155 -3 155 78 0 45 -21 140 -53 247
-9 28 -25 88 -37 135 -34 132 -40 148 -55 154 -8 3 -15 17 -15 32 0 26 40 105
67 132 25 26 15 35 -32 28 -41 -7 -46 -5 -54 16 -17 41 -60 186 -102 341 -23
84 -48 158 -57 165 -11 8 -60 12 -166 11 -83 -1 -158 -3 -167 -6z m137 -540
c16 -79 40 -123 66 -123 22 0 4 -33 -30 -54 -58 -37 -253 -133 -284 -141 -25
-6 -28 -4 -28 18 0 27 34 94 110 217 25 41 56 96 69 123 25 51 49 71 67 56 6
-5 20 -48 30 -96z"
          />
          <path
            d="M7670 6725 c-210 -59 -381 -258 -404 -470 -13 -126 32 -311 95 -385
l29 -35 -35 75 c-47 101 -63 210 -47 314 12 75 39 148 56 150 11 1 39 -99 39
-136 0 -16 4 -28 9 -28 4 0 19 -24 33 -52 13 -29 34 -70 47 -91 19 -32 20 -41
10 -58 -12 -18 -11 -19 5 -11 10 5 23 7 29 5 6 -2 14 4 16 15 5 19 196 154
242 172 14 5 26 13 26 18 0 6 121 69 355 184 l40 19 -44 -7 c-72 -11 -312
-116 -355 -155 -13 -11 -46 -12 -46 -1 0 5 -18 38 -40 75 -22 36 -40 68 -40
71 0 5 99 61 204 115 67 34 70 34 149 -31 63 -53 60 -34 -5 28 -60 57 -183
136 -242 154 -54 17 -29 30 59 30 142 0 269 -55 372 -163 81 -84 83 -95 37
-154 -67 -86 -269 -277 -365 -345 -163 -117 -348 -204 -455 -215 l-60 -6 -32
41 c-18 22 -49 74 -68 114 -19 40 -33 64 -30 53 19 -77 37 -129 55 -159 29
-47 12 -55 -44 -19 -58 36 -78 78 -84 178 -6 87 -12 88 -31 7 -14 -62 -8 -118
20 -172 91 -179 411 -131 740 109 91 66 259 233 319 318 50 69 121 197 121
216 0 20 -158 167 -179 167 -4 0 21 -29 55 -63 63 -65 101 -124 88 -135 -4 -4
-9 -1 -12 7 -11 32 -93 120 -155 166 -97 74 -167 97 -307 101 -82 3 -131 0
-170 -11z m123 -90 c69 -28 137 -70 137 -85 0 -5 -28 -21 -62 -37 -35 -15 -92
-42 -127 -61 -52 -27 -66 -31 -76 -20 -30 33 -96 151 -91 163 7 20 96 64 129
65 15 0 55 -11 90 -25z m-227 -127 c22 -29 47 -62 56 -72 26 -29 22 -42 -19
-67 -21 -13 -65 -43 -97 -67 -35 -25 -63 -39 -68 -34 -13 13 -38 111 -38 149
0 24 12 45 51 87 28 31 57 56 64 56 7 0 30 -24 51 -52z m114 -155 c27 -33 90
-121 90 -126 0 -4 -26 -24 -57 -46 -32 -22 -83 -60 -113 -85 -30 -25 -60 -46
-66 -46 -14 0 -75 134 -77 169 -2 21 13 35 92 88 51 35 99 63 105 63 6 0 18
-8 26 -17z"
          />
          <path
            d="M690 5791 c0 -693 3 -947 12 -966 7 -15 38 -40 77 -62 96 -53 292
-163 481 -271 91 -52 188 -107 215 -122 28 -15 136 -76 240 -135 105 -59 215
-121 245 -138 30 -17 72 -41 92 -54 21 -13 40 -23 42 -23 3 0 27 -13 53 -28
64 -38 179 -102 225 -125 22 -11 52 -17 75 -15 37 3 40 6 100 108 34 58 90
152 125 209 35 58 75 125 89 150 15 25 86 145 158 266 103 173 128 222 118
232 -9 9 -90 10 -347 3 -416 -11 -800 -13 -822 -5 -16 6 -93 124 -173 264 -17
31 -78 133 -135 226 -57 94 -112 186 -123 205 -10 19 -40 71 -67 115 -97 164
-243 412 -283 480 -22 39 -50 86 -63 105 -32 51 -105 171 -121 200 -49 91
-190 315 -200 318 -10 3 -13 -189 -13 -937z"
          />
          <path
            d="M8410 6135 c-54 -73 -252 -275 -335 -341 -82 -65 -213 -149 -306
-195 -158 -79 -277 -112 -409 -113 -82 0 -103 3 -150 25 -66 31 -96 61 -132
131 -21 41 -27 47 -28 29 -1 -84 61 -165 154 -200 46 -17 79 -21 177 -21 131
0 191 11 305 58 59 24 79 28 139 24 140 -8 321 56 428 149 128 112 214 305
205 459 l-3 55 -45 -60z m-35 -112 c-4 -10 -11 -30 -15 -44 -22 -75 -100 -182
-173 -241 -80 -64 -268 -143 -266 -112 0 5 37 35 82 66 85 59 238 202 317 296
44 52 68 68 55 35z"
          />
          <path
            d="M6290 4803 c-216 -34 -377 -127 -439 -255 -29 -61 -38 -175 -21 -249
34 -142 134 -228 328 -281 109 -30 426 -33 517 -4 145 45 246 126 279 223 25
74 27 73 -74 73 -87 0 -90 -1 -104 -27 -18 -35 -72 -86 -111 -106 -49 -24
-135 -40 -215 -41 -158 -2 -280 70 -314 183 -20 68 -20 94 -1 159 34 113 116
162 284 170 212 10 357 -61 371 -180 l5 -43 83 -3 82 -3 0 186 0 186 -82 -3
c-80 -3 -83 -4 -81 -26 4 -34 -16 -36 -79 -8 -97 43 -167 56 -289 55 -63 -1
-125 -3 -139 -6z"
          />
          <path
            d="M3514 4787 c-2 -7 -3 -76 -2 -152 l3 -140 80 0 80 0 3 78 c2 64 6 77
20 78 16 0 228 0 245 -1 8 0 10 -451 1 -474 -5 -13 -22 -16 -85 -16 l-79 0 0
-80 0 -80 295 0 295 0 0 80 0 80 -85 0 -85 0 0 245 c0 135 3 245 8 245 13 1
237 2 249 1 8 -1 13 -27 15 -78 l3 -78 78 -3 78 -3 -3 153 -3 153 -553 3
c-447 2 -554 0 -558 -11z"
          />
          <path
            d="M4747 4793 c-4 -3 -7 -35 -7 -70 l0 -63 33 -2 c17 -1 41 -2 52 -3 11
-1 27 -3 35 -4 13 -1 15 -34 13 -238 -2 -131 -3 -241 -3 -245 0 -5 -29 -8 -65
-8 l-65 0 0 -80 0 -80 495 0 495 0 0 155 0 155 -80 0 -80 0 0 -75 0 -75 -220
0 -220 0 0 90 0 90 145 0 145 0 0 70 0 69 -142 3 -143 3 -5 83 c-4 68 -2 82
10 83 14 0 406 0 423 -1 4 0 7 -33 7 -74 l0 -74 30 -7 c17 -4 53 -4 80 0 l50
7 0 149 0 149 -488 0 c-269 0 -492 -3 -495 -7z"
          />
          <path
            d="M7046 4792 c-3 -3 -6 -34 -6 -68 0 -71 8 -78 83 -72 l42 3 3 -247 2
-248 -65 0 -65 0 0 -80 0 -80 265 0 265 0 0 80 0 80 -70 0 -70 0 0 90 0 90
225 0 225 0 0 -90 0 -90 -65 0 -65 0 0 -80 0 -80 265 0 265 0 0 80 0 80 -70 0
-70 0 2 248 3 247 24 -3 c13 -2 44 -1 68 3 l43 6 0 70 0 70 -265 -1 -266 -1 3
-72 3 -72 60 0 60 0 3 -88 3 -87 -226 0 -225 0 0 84 0 83 38 7 c20 3 52 6 70
6 l32 0 0 70 0 71 -259 -2 c-143 0 -262 -4 -265 -7z"
          />
          <path
            d="M1974 3527 c-129 -50 -220 -177 -240 -334 -7 -59 16 -147 44 -170 48
-38 99 -56 157 -56 63 -1 185 34 204 57 6 7 17 39 24 72 12 53 17 60 44 68 22
7 33 17 36 35 3 14 3 29 0 33 -2 5 -47 8 -99 8 -92 0 -95 -1 -104 -26 -14 -37
-13 -41 15 -48 17 -4 25 -13 25 -29 -1 -66 -28 -87 -116 -87 -97 0 -131 44
-121 154 11 114 57 201 127 236 45 23 113 26 150 7 21 -11 24 -19 22 -51 -4
-37 -3 -37 30 -34 30 3 34 7 44 48 7 25 15 52 19 61 4 12 -4 23 -30 39 -44 27
-181 37 -231 17z"
          />
          <path
            d="M4947 3533 c-35 -9 -50 -83 -17 -83 11 0 20 -7 20 -16 0 -13 -45
-200 -81 -336 -7 -28 -16 -39 -35 -44 -15 -4 -30 -17 -35 -32 -18 -48 -9 -52
113 -52 182 0 270 31 357 125 48 52 75 108 92 194 24 126 -15 204 -120 235
-52 15 -247 21 -294 9z m272 -117 c23 -24 26 -34 24 -91 -1 -69 -35 -160 -76
-203 -29 -32 -83 -58 -134 -67 -35 -5 -44 -4 -49 9 -5 14 27 160 77 350 l10
39 61 -6 c48 -4 66 -11 87 -31z"
          />
          <path
            d="M5933 3530 c-21 -9 -33 -37 -49 -118 -6 -27 -3 -32 19 -37 40 -10 66
3 75 38 7 28 13 32 45 35 32 3 37 0 37 -18 0 -25 -77 -339 -88 -359 -5 -8 -21
-16 -38 -18 -34 -4 -54 -27 -54 -60 0 -23 1 -23 129 -23 l129 0 11 31 c13 38
7 49 -25 49 -14 0 -27 6 -30 13 -5 14 67 321 86 365 7 17 18 22 46 22 36 0 36
0 30 -35 -6 -31 -4 -35 19 -41 43 -11 67 7 81 62 7 27 13 59 14 72 l0 22 -187
0 c-104 0 -197 2 -208 5 -11 2 -30 0 -42 -5z"
          />
          <path
            d="M8123 3523 c-53 -18 -135 -91 -170 -150 -33 -57 -63 -163 -63 -223 1
-139 129 -218 291 -180 90 21 106 33 118 90 17 79 16 82 -26 78 -32 -3 -38 -7
-45 -36 -10 -39 -38 -52 -109 -52 -43 0 -55 5 -81 30 -30 30 -30 32 -26 113
10 183 115 293 247 257 39 -10 41 -12 41 -50 0 -39 1 -40 34 -40 32 0 35 3 50
47 26 77 23 91 -30 113 -55 24 -168 26 -231 3z"
          />
          <path
            d="M770 3504 c-14 -36 -13 -42 10 -49 11 -3 20 -13 20 -21 0 -8 -20 -96
-45 -195 -25 -99 -45 -183 -45 -185 0 -2 -9 -4 -19 -4 -38 0 -51 -11 -51 -46
l0 -34 178 0 c97 0 186 3 198 6 15 4 24 19 32 56 19 81 17 90 -20 86 -27 -2
-33 -8 -40 -35 l-9 -33 -84 0 c-62 0 -85 3 -85 13 0 6 9 44 18 82 l18 70 48 6
c49 5 48 6 62 -38 6 -20 52 -15 64 6 5 11 16 48 25 82 11 49 12 65 2 71 -21
14 -52 4 -62 -19 -8 -20 -17 -23 -63 -23 -42 0 -53 3 -49 14 3 8 11 41 17 74
7 34 17 62 24 64 6 2 42 2 79 0 l68 -4 -6 -30 c-7 -29 -6 -29 31 -26 36 3 38
5 51 53 23 92 37 85 -172 85 l-185 0 -10 -26z"
          />
          <path
            d="M1218 3506 c-12 -27 -4 -61 12 -51 16 10 23 -21 12 -57 -5 -18 -27
-104 -48 -190 -36 -147 -40 -158 -62 -158 -15 0 -26 -8 -33 -27 -18 -47 -8
-53 89 -53 95 0 109 6 114 51 3 20 -2 24 -29 27 -23 2 -33 8 -33 20 1 35 67
276 77 279 8 3 73 -138 146 -318 16 -40 30 -52 65 -57 35 -4 48 12 66 81 71
284 96 385 96 391 0 3 11 6 25 6 26 0 45 25 45 59 0 20 -6 21 -83 21 -51 0
-88 -5 -95 -12 -20 -20 -15 -59 8 -65 11 -3 20 -9 20 -13 0 -15 -70 -283 -76
-289 -9 -9 -23 22 -92 194 -35 88 -69 166 -74 173 -6 7 -35 12 -74 12 -59 0
-65 -2 -76 -24z"
          />
          <path
            d="M2372 3518 c-19 -19 -14 -56 8 -63 11 -3 20 -12 19 -18 0 -7 -21 -95
-47 -196 -47 -184 -48 -185 -79 -193 -21 -5 -32 -15 -35 -31 -8 -43 -1 -47 92
-47 91 0 101 4 114 47 6 19 2 25 -19 33 -17 7 -25 15 -22 25 4 8 17 60 31 117
14 57 30 119 36 138 5 19 14 54 20 77 8 31 16 42 33 45 23 3 53 51 42 68 -9
15 -178 13 -193 -2z"
          />
          <path
            d="M2626 3508 c-9 -36 -7 -48 9 -48 9 0 18 -6 21 -14 6 -15 -82 -376
-94 -388 -4 -3 -16 -8 -27 -10 -26 -4 -45 -56 -25 -68 18 -12 160 -13 177 -2
6 4 16 20 22 35 10 25 8 27 -17 33 -50 11 -51 11 -42 46 49 208 66 261 81 256
6 -2 39 -73 75 -158 94 -226 97 -229 147 -216 30 7 36 25 102 296 42 171 49
191 68 185 20 -8 38 10 40 41 l2 29 -78 3 c-50 2 -84 -1 -93 -9 -15 -12 -20
-59 -6 -59 4 0 13 -4 21 -9 11 -7 7 -33 -19 -143 -34 -144 -43 -169 -59 -153
-8 9 -44 93 -113 269 -42 108 -40 106 -117 106 -63 0 -69 -2 -75 -22z"
          />
          <path
            d="M3218 3506 c-12 -27 -4 -61 12 -51 24 15 18 -35 -26 -214 -43 -174
-49 -191 -69 -191 -45 0 -65 -62 -22 -74 34 -9 340 -7 355 3 15 9 46 117 37
131 -3 6 -19 10 -35 10 -25 0 -30 -5 -36 -35 l-6 -35 -82 0 c-46 0 -85 2 -88
5 -3 3 4 42 16 85 l21 80 52 0 c46 0 53 -3 53 -20 0 -21 45 -40 59 -24 11 12
41 125 41 153 0 19 -5 22 -32 19 -25 -2 -34 -8 -36 -25 -3 -21 -9 -23 -58 -23
-30 0 -54 4 -54 10 0 24 23 116 32 128 7 7 37 12 84 12 l74 0 -6 -30 c-6 -28
-5 -30 25 -30 33 0 35 3 60 81 19 62 27 59 -174 59 l-185 0 -12 -24z"
          />
          <path
            d="M3662 3518 c-20 -20 -15 -59 8 -65 11 -3 20 -9 20 -13 0 -14 -81
-341 -90 -366 -6 -14 -18 -24 -29 -24 -24 0 -38 -18 -37 -50 1 -25 3 -25 92
-28 97 -3 109 2 116 48 2 18 -2 25 -17 28 -33 6 -34 14 -17 82 16 61 19 65 48
68 l31 3 29 -113 29 -113 168 0 c180 0 187 2 187 53 0 15 -6 22 -20 22 -27 0
-25 16 6 62 l27 38 83 0 c45 0 85 -3 87 -7 3 -5 7 -26 8 -48 4 -37 2 -40 -23
-43 -20 -2 -30 -10 -34 -28 -12 -48 -2 -54 99 -54 101 0 127 12 127 58 0 18
-6 22 -29 22 -28 0 -30 2 -35 53 -9 75 -27 260 -34 347 l-7 75 -51 3 -52 3
-29 -48 c-15 -26 -37 -65 -48 -85 -11 -21 -23 -38 -27 -38 -5 0 -8 -5 -8 -11
0 -7 -31 -63 -70 -125 -38 -63 -70 -119 -70 -124 0 -6 -4 -10 -9 -10 -5 0 -11
-8 -14 -19 -5 -20 -51 -30 -112 -23 -28 3 -36 9 -41 31 -3 14 -12 45 -20 69
-21 63 -18 77 24 105 53 38 84 89 90 147 5 59 -11 90 -61 114 -44 20 -275 24
-295 4z m241 -80 c22 -11 22 -58 1 -99 -18 -36 -67 -59 -125 -59 -35 0 -40 3
-34 18 4 9 14 46 23 82 8 36 20 68 26 72 13 8 83 -1 109 -14z m475 -131 l3
-87 -65 0 c-36 0 -66 3 -66 7 0 3 12 27 27 52 87 150 97 153 101 28z"
          />
          <path
            d="M5612 3518 c-12 -15 -172 -278 -172 -283 0 -9 -104 -171 -114 -177
-6 -4 -21 -8 -33 -8 -16 0 -25 -8 -29 -25 -12 -50 -3 -55 100 -55 84 0 97 2
106 19 16 32 12 58 -11 64 l-21 6 23 41 23 41 86 -3 85 -3 0 -40 c0 -36 -3
-40 -27 -43 -20 -2 -28 -10 -33 -32 -4 -16 -4 -34 0 -40 9 -15 218 -13 233 2
7 7 12 25 12 40 0 23 -4 28 -24 28 -14 0 -27 4 -30 9 -3 5 -15 107 -26 227
-12 121 -23 225 -25 232 -3 7 -24 12 -58 12 -32 0 -59 -5 -65 -12z m31 -204
c4 -42 4 -80 1 -85 -7 -12 -104 -11 -104 1 0 14 82 160 90 160 4 0 10 -34 13
-76z"
          />
          <path
            d="M6433 3343 c-61 -104 -122 -209 -137 -235 -21 -35 -35 -48 -59 -54
-37 -8 -47 -19 -47 -56 l0 -28 104 0 c99 0 104 1 110 23 10 37 7 57 -8 57 -26
0 -27 18 -4 54 l23 36 82 0 c79 0 83 -1 89 -24 9 -36 1 -59 -21 -62 -25 -4
-45 -31 -45 -61 0 -23 1 -23 119 -23 128 0 131 1 131 55 0 18 -6 24 -27 27
-34 4 -34 8 -58 273 -9 105 -19 193 -22 198 -2 4 -30 7 -62 7 l-58 0 -110
-187z m140 -30 c3 -42 4 -81 1 -85 -7 -12 -104 -10 -104 2 0 14 83 160 91 160
3 0 9 -35 12 -77z"
          />
          <path
            d="M7047 3523 c-13 -22 -35 -129 -28 -136 5 -5 24 -7 42 -5 29 3 35 7
39 33 5 28 9 30 52 33 l46 3 -8 -33 c-20 -86 -82 -344 -86 -355 -2 -7 -19 -13
-39 -13 -28 0 -38 -5 -47 -24 -22 -50 -9 -56 121 -56 l119 0 11 31 c13 39 8
49 -30 49 -16 0 -29 3 -29 8 0 4 21 94 47 200 l48 192 43 0 c43 0 43 0 40 -32
-3 -32 -2 -33 36 -33 37 0 39 2 52 45 8 25 14 57 14 73 l0 27 -219 0 c-121 0
-222 -3 -224 -7z"
          />
          <path
            d="M7530 3511 c-17 -33 -12 -61 10 -61 11 0 20 -7 20 -15 0 -9 -21 -99
-46 -200 -44 -176 -48 -185 -70 -185 -27 0 -51 -38 -41 -65 6 -13 31 -15 199
-13 l193 3 13 39 c7 21 13 52 15 70 2 29 0 31 -33 31 -30 0 -35 -4 -44 -32
l-9 -33 -78 0 c-92 0 -92 1 -61 105 l18 60 45 0 c38 0 44 -3 47 -23 3 -20 8
-23 40 -20 36 3 37 5 54 63 9 33 17 73 17 88 1 27 -1 28 -36 25 -31 -2 -39 -7
-41 -25 -3 -20 -9 -23 -53 -23 -55 0 -56 2 -38 78 17 68 22 72 100 72 l69 0
-6 -29 c-5 -24 -2 -29 18 -33 45 -8 55 1 71 64 9 34 14 66 11 70 -3 4 -88 8
-189 8 -172 0 -185 -1 -195 -19z"
          />
          <path
            d="M8467 3523 c-3 -5 -7 -21 -8 -38 -3 -25 0 -31 19 -33 12 -2 22 -5 22
-6 0 -8 -82 -335 -91 -363 -8 -24 -17 -33 -37 -35 -23 -3 -27 -8 -30 -40 l-3
-38 94 0 c101 0 114 6 119 51 2 18 -2 25 -19 27 -26 4 -26 4 -14 52 6 19 15
54 21 78 l11 42 105 0 c78 0 106 -3 110 -13 3 -8 -4 -45 -14 -83 -16 -59 -23
-70 -43 -73 -29 -4 -39 -17 -39 -53 l0 -28 94 0 c97 0 107 4 120 47 6 19 2 25
-19 33 -18 7 -25 15 -21 28 43 144 56 229 54 335 l-3 112 -44 3 c-49 4 -61 -6
-61 -50 0 -21 5 -28 20 -28 24 0 25 -9 2 -85 l-18 -60 -99 -3 c-54 -1 -104 -1
-111 2 -9 4 -8 20 5 73 15 61 20 68 44 71 20 3 29 11 35 35 5 18 7 35 5 40 -2
4 -49 7 -103 7 -54 0 -101 -3 -103 -7z"
          />
          <path
            d="M607 2903 c-4 -3 -7 -10 -7 -15 0 -4 918 -8 2040 -8 1810 0 2040 2
2040 15 0 13 -230 15 -2033 15 -1119 0 -2037 -3 -2040 -7z"
          />
          <path
            d="M4754 2899 c-3 -6 -2 -15 3 -20 5 -5 406 -8 993 -7 929 3 985 4 988
21 3 16 -49 17 -987 17 -702 0 -992 -3 -997 -11z"
          />
          <path
            d="M6897 2903 c-4 -3 -7 -10 -7 -15 0 -4 448 -8 995 -8 878 0 995 2 995
15 0 13 -116 15 -988 15 -544 0 -992 -3 -995 -7z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
